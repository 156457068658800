<template>
  <div class="container tasks-search">
    <div class="tasks-search-header">
      <div class="header-title has-text-weight-bold">
        {{ $t('tasks.search.headerTitle') }}
      </div>
      <search-field
        :isSearching.sync="isSearching"
        @search="search"/>
      <mark-as-selector
        position="is-bottom-left"
        :disabled="selectedTasks.length === 0"
        @change="onMarkAs"/>
    </div>
    <div class="tasks-search-body">
      <div
        v-if="isSearching"
        class="tasks-searching">
        {{ $t('tasks.search.searching') }}
      </div>
      <div
        v-else-if="!lastSearchTerm"
        class="tasks-search-empty">
        {{ $t('tasks.search.empty') }}
      </div>
      <div
        v-else-if="!isSearching && searchedTasks.length === 0"
        class="tasks-searching">
        {{ $t('tasks.table.empty') }}
      </div>
      <tasks-table
        v-else
        :tasks="searchedTasks"
        :selectedTasks.sync="selectedTasks"
        :isSearch="true"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import markTasksAsMixin from '@/mixins/mark-tasks-as-mixin'

const MarkAsSelector = () => import('@components/MarkAsSelector')
const SearchField = () => import('@components/SearchField')
const TasksTable = () => import('@components/TasksTable')

export default {
  name: 'tasks-search',
  components: {
    MarkAsSelector,
    SearchField,
    TasksTable
  },
  mixins: [markTasksAsMixin],
  props: {
    tasks: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isSearching: false,
      selectedTasks: [],
      lastSearchTerm: null
    }
  },
  computed: {
    ...mapGetters('task', [
      'searchTasks'
    ]),
    searchedTasks() {
      if (!this.lastSearchTerm) {
        return []
      }
      return this.searchTasks
    }
  },
  watch: {
    searchedTasks() {
      this.selectedTasks = []
    }
  },
  created() {
    this.addApiListeners('task/searchTasks', this.onSearchTasksComplete)
    this.focusSearch()
  },
  methods: {
    ...mapActions('task', [
      'searchTasksRequest'
    ]),
    focusSearch() {
      setTimeout(() => {
        const search = document.querySelector('input[type="search"]')
        if (search) {
          search.focus()
        } else {
          this.focusSearch()
        }
      }, 25)
    },
    search(searchTerm) {
      this.lastSearchTerm = searchTerm
      if (!searchTerm) {
        this.onSearchTasksComplete()
        return
      }

      this.isSearching = true
      this.searchTasksRequest({
        q: searchTerm
      })
    },
    onSearchTasksComplete() {
      this.isSearching = false
    },
    onMarkAs(option) {
      const tasks = this.searchedTasks.filter(task => this.selectedTasks.includes(task.id))
      this.markTasksAs(tasks, option)
    }
  }
}
</script>

<style lang="scss" scoped>
.tasks-search {
  margin-top: $space-l;

  .tasks-search-header {
    border-bottom: 1px solid $grey-light;

    .header-title {
      margin-bottom: $space-m;
    }

    .mark-as-selector {
      margin-left: $space-xs;
    }
  }

  .tasks-search-body {
    .tasks-search-empty,
    .tasks-searching {
      margin-top: $space-xl;
      margin-bottom: $space-l;
      color: $grey-light;
      text-align: center;
      @extend %display_light;
    }

    .tasks-table {
      &::v-deep table {
        background-color: transparent;
      }
    }
  }
}
</style>
